import React, { useRef } from 'react';
import styled from 'styled-components';
import { Flex, MaskImg, Text } from '../Ui';
import UserImage from '../../assets/images/userAvatar.svg';
import Logout from '../Logout';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UpdateIcon } from '../../assets/images/update.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileOption from '../profileOption';
import { AiOutlineSetting } from 'react-icons/ai';
import { ReactComponent as Setting } from '../../assets/images/setting.svg';
import { ReactComponent as Situation } from '../../assets/images/situation.svg';
import { ReactComponent as Point } from '../../assets/images/point.svg';
import { ReactComponent as Booster } from '../../assets/images/booster.svg';
import { ReactComponent as Missioni } from '../../assets/images/missioni.svg';
import { ReactComponent as Ordini } from '../../assets/images/ordine.svg';
import { ReactComponent as Gallery } from '../../assets/images/gallery.svg';

import { GrGallery } from 'react-icons/gr';
import { numberWithDots } from '../../utils/utils';

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  //max-width: 1519px;
  //margin: 0 auto;
  background-color: #00000030;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
`;
const Container = styled.div`
  width: 300px;

  background-color: ${({ theme }) => theme.bg_box};
  border: 1px solid ${({ theme }) => theme.colore_bordo_box};
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  z-index: 999999;
`;
const UserContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  a {
    margin-left: auto;
  }

  svg {
    stroke: ${({ theme }) => theme.colore_testo_box};
    width: 20px;
  }
`;

const UserImg = styled.div`
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  width: 64px;
  height: 64px;
  .icon {
    background-color: ${({ theme }) => '#333'};
    display: block;
    width: 100%;
    height: 100%;
  }
  &:hover {
    .icon {
      background-color: ${({ theme }) => '#333'};
    }
  }
`;
const UserName = styled.div``;

function UserPopUp({ setToggelDropDown, logout, show_logout }) {
  const { firstname, lastname, score, score_pending, company } = useSelector(
    state => state.user.user.userinfo || {},
  );

  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  const show_catalog = useSelector(state => state.app.config.settings.show_catalog);
  const appName = useSelector(state => state.app.config.code);

  const ref = useRef();
  const [t] = useTranslation();
  const clickHandler = event => {
    if (!ref.current.contains(event.target)) {
      setToggelDropDown(prev => !prev);
    }
  };
  return (
    <>
      <Overlay onClick={e => clickHandler(e)}></Overlay>
      <Container ref={ref}>
        <Text as='h4' capit bold size={18} text_box>
          il tuo profilo
        </Text>
        <UserContent>
          <div>
            {Boolean(show_logout) ? (
              <UserImg onClick={() => setToggelDropDown(false)}>
                <Link to='/user/profile'>
                  <MaskImg src={UserImage} className='icon' />
                </Link>
              </UserImg>
            ) : (
              <UserImg>
                <MaskImg src={UserImage} className='icon' />
              </UserImg>
            )}
          </div>
          <UserName>
            <Text as='p' size={18} capit bold style={{ marginBottom: '7px' }} text_box>
              {firstname ? firstname : '' + lastname ? lastname : ''}
              {!firstname && !lastname && company}
            </Text>
          </UserName>
          {Boolean(show_logout) && (
            <Link to='/user/profile'>
              <UpdateIcon onClick={() => setToggelDropDown(false)} />
            </Link>
          )}
        </UserContent>
        <Flex justify='space-between' align='center'>
          {Boolean(show_catalog) && (
            <div>
              <Text size={18} capit as='div' style={{ marginTop: '10px' }} text_box>
                saldo punti {numberWithDots(score)}
              </Text>

              {appName && appName === 'WURTH' ? (
                <Text align='center' size={18} text_box>
                  Punti da confermare: {numberWithDots(score_pending)}
                </Text>
              ) : null}
            </div>
          )}
          {Boolean(show_logout) && <Logout onClick={logout} label={t('auth.logoutlabel')} />}
        </Flex>

        {option?.menuSettings?.settings?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.settings?.label}
            Svg={Setting}
            img={option?.menuSettings?.settings?.icon_url}
            width='40px'
            height='50px'
            link='/user/options/setting'
            setToggelDropDown={setToggelDropDown}
          />
        )}
        {option?.menuSettings?.situation?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.situation?.label}
            Svg={Situation}
            img={option?.menuSettings?.situation?.icon_url}
            width='50px'
            height='50px'
            link='/user/options/situation'
            setToggelDropDown={setToggelDropDown}
          />
        )}
        {option?.menuSettings?.points?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.points?.label}
            Svg={Point}
            img={option?.menuSettings?.points?.icon_url}
            width='55px'
            height='50px'
            link='/user/options/point'
            setToggelDropDown={setToggelDropDown}
          />
        )}
        {option?.menuSettings?.orders?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.orders?.label}
            Svg={Ordini}
            img={option?.menuSettings?.orders?.icon_url}
            width='55px'
            height='50px'
            link='/user/options/ordini'
            setToggelDropDown={setToggelDropDown}
          />
        )}

        {option?.menuSettings?.prizes?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.prizes?.label}
            Svg={Booster}
            img={option?.menuSettings?.prizes?.icon_url}
            height='50px'
            width='60px'
            link='/user/options/premi'
            setToggelDropDown={setToggelDropDown}
          />
        )}
        {option?.menuSettings?.booster?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.booster?.label}
            Svg={Booster}
            img={option?.menuSettings?.booster?.icon_url}
            width='60px'
            height='50px'
            link='/user/options/booster'
            setToggelDropDown={setToggelDropDown}
          />
        )}
        {option?.menuSettings?.missions?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.missions?.label}
            Svg={Missioni}
            img={option?.menuSettings?.missions?.icon_url}
            height='60px'
            link='/user/options/missioni'
            setToggelDropDown={setToggelDropDown}
          />
        )}
        {option?.menuSettings?.photogallery?.visibility === 'show' && (
          <ProfileOption
            text={option?.menuSettings?.photogallery?.label}
            Svg={Gallery}
            img={option?.menuSettings?.photogallery?.icon_url}
            height='50px'
            link='/user/options/photogallery'
            setToggelDropDown={setToggelDropDown}
          />
        )}
      </Container>
    </>
  );
}

export default UserPopUp;
